.app-top {
    height: 208px;
}
.app-top .navbar-brand {
    width: 203px;
    height: 93px;
    margin-top: 10px;
    background: url(../img/logo.png) 0 0 no-repeat;
}
.app-top .logo-top-wp .ellipse-top-left {
    position: absolute;
    background: url(../img/ellipse-top-left.png) 0 0 no-repeat;
    width: 649px;
    height: 653px;
    z-index: -1;
    left: -180px;
    top: -498px;
}
.images-wrap div img {
    max-width: 100%;
}
.react-slideshow-wrapper.slide {
    width: 540px;
    height: 400px;
}
.react-slideshow-wrapper .images-wrap {
    height: 400px;
}
.react-slideshow-wrapper .images-wrap div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 400px;
}
.react-slideshow-wrapper .images-wrap img {
    max-height: 400px;
}
@media only screen and (max-width: 768px) {
    .react-slideshow-container {
        justify-content: center;
    }
    .react-slideshow-container + div.indicators {
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 580px) {
    .react-slideshow-wrapper .images-wrap img {
        height: auto;
        max-height: 100%;
    }
    .app-top {
        background: url(../img/ellipse-top-left.png) center -471px no-repeat;
    }
    .app-top .logo-top-wp .ellipse-top-left {
        display: none;
    }
    .app-top .navbar-brand {
        margin-top: 17px;
        margin-left: 37px;
    }
}
@media only screen and (max-width: 425px) {
    .app-top .navbar .ellipse-top-left {
        width: 507px;
    }
}

.language-selector {
  position: absolute;
  top: 20px;
  right: 10px;
}
.language-selector .language.dropdown .dropdown-menu {
  min-width: 1rem;
}
@media only screen and (max-width: 425px) {
  .dropdown-toggle.btn.btn-custom {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0 !important;
  }
}
